/*
  IMPORTANT NOTE:
  This file is modified by AST at build time.
*/
import React, {createContext, memo} from 'react';
import PropTypes from 'prop-types';
import CtaLevel from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__CtaLevel';
import TestExpAbCd3Fg1 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__TestExpAbCd3Fg1';
import _010119700000123129992359 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010119700000123129992359';
import _010520230000011220231500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010520230000011220231500';
import Segment from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__Segment';
import _103020230000110320230000 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___103020230000110320230000';
import _1026202310272023 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___1026202310272023';
import _01012024 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___01012024';
import Experiment from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__Experiment';
import Region from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__Region';
import Country from '../plasmic_components/plasmic/placeit_landing_pages/PlasmicGlobalVariant__Country';
// insert imports

// in addition to the variant contexts needed by plasmic... we can use this
// single variant context to hold all experiments... easier to use in our code.
export const VariantContext = createContext({
  variations: {},
  setVariations: async () => null,
});
const PlasmicVariants = memo(
  class extends React.Component {
    render() {
      if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
        console.log('PlasmicVariants Render', Date.now());
      return (
        <VariantContext.Provider value={this.props}>
          <Country.Provider value={this.props.country}>
            <Region.Provider value={this.props.region}>
              <Experiment.Provider value={this.props.experiment}>
                <_01012024.Provider value={this.props._01012024}>
                  <_1026202310272023.Provider value={this.props._1026202310272023}>
                    <_103020230000110320230000.Provider
                      value={this.props._103020230000110320230000}
                    >
                      <Segment.Provider value={this.props.segment}>
                        <_010520230000011220231500.Provider
                          value={this.props._010520230000011220231500}
                        >
                          <_010119700000123129992359.Provider
                            value={this.props._010119700000123129992359}
                          >
                            <TestExpAbCd3Fg1.Provider value={this.props.testexpabcd3fg1}>
                              <CtaLevel.Provider value={this.props.ctalevel}>
                                {this.props.children}
                              </CtaLevel.Provider>
                            </TestExpAbCd3Fg1.Provider>
                          </_010119700000123129992359.Provider>
                        </_010520230000011220231500.Provider>
                      </Segment.Provider>
                    </_103020230000110320230000.Provider>
                  </_1026202310272023.Provider>
                </_01012024.Provider>
              </Experiment.Provider>
            </Region.Provider>
          </Country.Provider>
        </VariantContext.Provider>
      );
    }
  }
);
PlasmicVariants.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PlasmicVariants;
export const ContextNames = [
  'ctalevel',
  'testexpabcd3fg1',
  '_010119700000123129992359',
  '_010520230000011220231500',
  'segment',
  '_103020230000110320230000',
  '_1026202310272023',
  '_01012024',
  'experiment',
  'region',
  'country',
];
